import { inject }                         from 'aurelia-framework';
import { BaseFilterFormSchema }           from 'resources/classes/base-filter-form-schema';
import { CountiesRepository }             from 'modules/administration/locations/counties/services/repository';
import { DialogService }                  from 'aurelia-dialog';
import { DistrictsRepository }            from 'modules/administration/locations/districts/services/repository';
import { EventAggregator }                from 'aurelia-event-aggregator';
import { I18N }                           from 'aurelia-i18n';
import { InspectingEntitiesRepository }   from 'modules/entities/inspecting-entities/services/repository';
import { LiftTypesRepository }            from 'modules/management/lift-types/services/repository';
import { LocalStorage }                   from 'resources/services/local-storage';
import { MaintenanceCompaniesRepository } from 'modules/entities/maintenance-companies/services/repository';
import { OwnersRepository }               from 'modules/entities/owners/services/repository';
import { ParishesRepository }             from 'modules/administration/locations/parishes/services/repository';
import { ProcedureResultsRepository }     from 'modules/procedures/results/services/repository';

@inject(LocalStorage,
    EventAggregator,
    I18N,
    DialogService,
    DistrictsRepository,
    CountiesRepository,
    InspectingEntitiesRepository,
    LiftTypesRepository,
    MaintenanceCompaniesRepository,
    OwnersRepository,
    ParishesRepository,
    ProcedureResultsRepository
)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        code:                          null,
        owners:                        [],
        maintenance_companies:         [],
        inspecting_entities:           [],
        lift_types:                    [],
        results:                       [],
        new_certificate_deadline_from: null,
        new_certificate_deadline_to:   null,
        execution_date_from:           null,
        execution_date_to:             null,
        districts:                     [],
        counties:                      [],
        parishes:                      [],
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     * @param districtsRepository
     * @param countiesRepository
     * @param liftTypesRepository
     * @param maintenanceCompaniesRepository
     * @param ownersRepository
     * @param parishesRepository
     * @param procedureResultsRepository
     */
    constructor(storage, eventAggregator, i18n, dialogService, districtsRepository, countiesRepository, inspectingEntitiesRepository, liftTypesRepository, maintenanceCompaniesRepository, ownersRepository, parishesRepository, procedureResultsRepository) {
        super(storage, eventAggregator, i18n, dialogService);

        this.districtsRepository            = districtsRepository;
        this.countiesRepository             = countiesRepository;
        this.inspectingEntitiesRepository   = inspectingEntitiesRepository;
        this.liftTypesRepository            = liftTypesRepository;
        this.maintenanceCompaniesRepository = maintenanceCompaniesRepository;
        this.ownersRepository               = ownersRepository;
        this.parishesRepository             = parishesRepository;
        this.procedureResultsRepository     = procedureResultsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.code = {
            type:     'text',
            key:      'code',
            label:    'form.field.lift-number',
            size:     4,
            required: false,
        };

        this.lift_types = {
            type:         'multiselect-native',
            key:          'lift_types',
            label:        'form.field.lift-types',
            size:         4,
            remoteSource: this.liftTypesRepository.all.bind(this.liftTypesRepository),
            required:     false,
        };

        this.owners = {
            type:         'multiselect-native',
            key:          'owners',
            label:        'form.field.owners/administrators',
            size:         4,
            remoteSource: this.ownersRepository.all.bind(this.ownersRepository),
            required:     false,
        };

        this.maintenance_companies = {
            type:         'multiselect-native',
            key:          'maintenance_companies',
            label:        'form.field.maintenance-companies',
            size:         4,
            remoteSource: this.maintenanceCompaniesRepository.all.bind(this.maintenanceCompaniesRepository),
            required:     false,
        };

        this.inspecting_entities = {
            type:         'multiselect-native',
            key:          'inspecting_entities',
            label:        'form.field.inspecting-entities',
            size:         4,
            remoteSource: this.inspectingEntitiesRepository.all.bind(this.inspectingEntitiesRepository),
            required:     false,
        };

        this.results = {
            type:         'multiselect-native',
            key:          'results',
            label:        'form.field.results',
            size:         4,
            remoteSource: this.procedureResultsRepository.all.bind(this.procedureResultsRepository),
            required:     false,
        };

        this.new_certificate_deadline_from = {
            type:            'material-ui-date-picker',
            key:             'new_certificate_deadline_from',
            label:           'form.field.certificate-deadline-from',
            size:            4,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.new_certificate_deadline_to = {
            type:            'material-ui-date-picker',
            key:             'new_certificate_deadline_to',
            label:           'form.field.certificate-deadline-to',
            size:            4,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.execution_date_from = {
            type:            'material-ui-date-picker',
            key:             'execution_date_from',
            label:           'form.field.inspection-date-from',
            size:            4,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.execution_date_to = {
            type:            'material-ui-date-picker',
            key:             'execution_date_to',
            label:           'form.field.inspection-date-to',
            size:            4,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.districts = {
            type:           'multiselect-native',
            key:            'districts',
            label:          'form.field.districts',
            size:           4,
            remoteSource:   this.districtsRepository.all.bind(this.districtsRepository),
            processResults: (row) => this.processLocationResults(row),
            required:       false,
            observers:      [
                () => this.handleDistrictsChanged(viewModel),
            ],
        };

        this.counties = {
            type:                   'multiselect-native',
            key:                    'counties',
            label:                  'form.field.counties',
            size:                   4,
            remoteSource:           (params) => viewModel.filterModel.districts.length ? this.countiesRepository.allByDistricts(params) : Promise.resolve([]),
            remoteSourceParameters: () => viewModel.filterModel.districts,
            processResults:         (row) => this.processLocationResults(row),
            required:               false,
            attributes:             {
                disabled: !viewModel.filterModel.districts.length,
            },
            observers:              [
                () => this.handleCountiesChanged(viewModel),
            ],
        };

        this.parishes = {
            type:                   'multiselect-native',
            key:                    'parishes',
            label:                  'form.field.parishes',
            size:                   4,
            remoteSource:           (params) => viewModel.filterModel.counties.length ? this.parishesRepository.allByCounties(params) : Promise.resolve([]),
            remoteSourceParameters: () => viewModel.filterModel.counties,
            processResults:         (row) => this.processLocationResults(row),
            required:               false,
            attributes:             {
                disabled: !viewModel.filterModel.counties.length,
            },
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {listingId: viewModel.listingId, criteria: viewModel.filterModel}),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.code],
            [this.owners, this.maintenance_companies, this.inspecting_entities],
            [this.lift_types, this.new_certificate_deadline_from, this.new_certificate_deadline_to],
            [this.districts, this.counties, this.parishes],
            [this.results, this.execution_date_from, this.execution_date_to],
            [this.buttons],
        ];
    }

    /**
     * Processes the location fields results
     *
     * @param row
     */
    processLocationResults(row) {
        row.name = `${row.code} - ${row.name}`;

        return row;
    }

    /**
     * Handles the `districts` field changes
     *
     * @param viewModel
     */
    handleDistrictsChanged(viewModel) {
        if (viewModel.filterModel.districts.length) {
            this.counties.instance.fetchData().then(() => this.counties.instance.enable());
        } else {
            this.counties.instance.disable();
        }
    }

    /**
     * Handles the `counties` field changes
     *
     * @param viewModel
     */
    handleCountiesChanged(viewModel) {
        if (viewModel.filterModel.counties.length) {
            this.parishes.instance.fetchData().then(() => this.parishes.instance.enable());
        } else {
            this.parishes.instance.disable();
        }
    }

}
